import React from 'react';

import { css, Theme } from '@emotion/react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const overviewBandBox = (theme: Theme) => css({
  backgroundColor: theme.palette.backgroundDark.default,
});

const overViewContents = css({
  padding: '1.5rem 0',
  color: '#ffffff',
  lineHeight: '1.8rem',
  width: '90%',
  margin: '0 auto'
});

const brAdjust = (theme: Theme) => css({
  [theme.breakpoints.up('md')]: {
    display: 'none',
  }
});

const OverviewBand: React.VFC = () => {
  return (
    <Box css={overviewBandBox}>
      <Typography align="center" css={overViewContents}>
        建築・土木・リニューアルの
        <br css={brAdjust} />
        あらゆるご相談・お問い合わせ
        <br />
        その他、何でもお気軽に
        <br css={brAdjust} />
        お問合せください。
      </Typography>
    </Box>
  );
};

export default OverviewBand;
