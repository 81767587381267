import React from 'react';

import { css, Theme } from '@emotion/react';
import TextField from '@mui/material/TextField';

type ContentsFieldProps = {
  contents: string;
  setContents: React.Dispatch<React.SetStateAction<string>>;
};

const contactFieldStyles = css({
  margin: '1.5rem auto'
});

const ContentsField: React.VFC<ContentsFieldProps> = (props) => {
  const {contents, setContents} = props;

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setContents(event.target.value);
  };

  return (
    <TextField
      variant="standard"
      fullWidth
      multiline
      rows={6}
      required
      type="text"
      label="お問い合わせ内容（必須）"
      onChange={(event) => handleChange(event)}
      value={contents}
      css={contactFieldStyles}
    />
  );
};

export default ContentsField;
