import React, { useEffect, useState, useRef } from 'react';
import Axios from 'axios';
import { useAtom } from 'jotai';

import { css, Theme } from '@emotion/react';
import Box from '@mui/material/Box';

import NameField from '@/components/atoms/contact/NameField';
import EmailField from '@/components/atoms/contact/EmailField';
import ContentsField from '@/components/atoms/contact/ContentsField';
import SubmitButton from '@/components/atoms/contact/SubmitButton';

import {
  contactSuccessState,
  contactErrorState,
  backDropStatus,
} from '@/store/store';

import FormKeys from '@/others/contactFormKeys';

const formBoxStyles = css({
  width: '90%',
  maxWidth: '720px',
  margin: '1rem auto',
});

const ContactForm: React.VFC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contents, setContents] = useState('');
  const [emailIsCorrect, setEmailIsCorrect] = useState(true);
  const [clickable, setClickable] = useState(false);
  const [, setSuccessIsOpen] = useAtom(contactSuccessState);
  const [, setErrorIsOpen] = useAtom(contactErrorState);
  const [, setBackDropStatus] = useAtom(backDropStatus);
  const contactFormElement = useRef<HTMLFormElement>(null);

  const handleSubmit = () => {
    setBackDropStatus(true);

    const submitParams = new FormData();

    submitParams.append(FormKeys.name, name);
    submitParams.append(FormKeys.email, email);
    submitParams.append(FormKeys.contents, contents);

    Axios.post(FormKeys.corsProxy + FormKeys.action, submitParams)
      .then(() => {
        setName('');
        setEmail('');
        setContents('');
        setErrorIsOpen(false);

        contactFormElement.current?.reset();
        setBackDropStatus(false);
        setSuccessIsOpen(true);
      })
      .catch(() => {
        setSuccessIsOpen(false);
        setBackDropStatus(false);
        setErrorIsOpen(true);
      });
  };

  useEffect(() => {
    if (
      name !== '' &&
      email !== '' &&
      contents !== '' &&
      emailIsCorrect
    ) {
      setClickable(true);
    } else {
      setClickable(false);
    }
  }, [name, email, contents, emailIsCorrect]);

  return (
    <Box css={formBoxStyles}>
      <form ref={contactFormElement}>
        <NameField
          name={name}
          setName={setName}
        />
        <EmailField
          email={email}
          setEmail={setEmail}
          isCorrect={emailIsCorrect}
          setIsCorrect={setEmailIsCorrect}
        />
        <ContentsField
          contents={contents}
          setContents={setContents}
        />
        <SubmitButton
          clickable={clickable}
          handleClick={handleSubmit}
        />
      </form>
    </Box>
  );
};

export default ContactForm;
