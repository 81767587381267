import React from 'react';

import { css, Theme } from '@emotion/react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PhoneIcon from '@mui/icons-material/Phone';

const phoneContactBandBox = (theme: Theme) => css({
  background: theme.palette.secondaryGradation.default,
});

const phoneContactContents = css({
  padding: '1.5rem 0',
  color: '#ffffff',
  lineHeight: '2.7rem',
  width: '90%',
  margin: '0 auto'
});

const phoneIconAdjust = css({
  verticalAlign: 'sub'
});

const PhoneContactBand: React.VFC = () => {
  return (
    <Box css={phoneContactBandBox}>
      <Typography variant="h3" align="center" css={phoneContactContents}>
        お電話でのお問い合わせはこちらから
        <br />
        <PhoneIcon css={phoneIconAdjust} /> 049-269-5811
      </Typography>
    </Box>
  );
};

export default PhoneContactBand;
