import React from 'react';
import { useAtom } from 'jotai';

import { css, Theme } from '@emotion/react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { contactErrorState } from '@/store/store';

const errorAlert = css({
  position: 'fixed',
    top: '2vh',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '85%',
    zIndex: 1100,
    display: 'flex',
    alignItems: 'center',
});

const adjustAlertTitle = css({
  marginBottom: 0,
});

const ErrorAlert: React.VFC = () => {
  const [isOpen, setIsOpen] = useAtom(contactErrorState);

  return (
    <div>
      <Collapse in={isOpen}>
        <Alert
          severity="error"
          elevation={5}
          css={errorAlert}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setIsOpen(false)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle css={adjustAlertTitle}>
            入力内容をご確認ください
          </AlertTitle>
        </Alert>
      </Collapse>
    </div>
  );
};

export default ErrorAlert;
