import React from 'react';

import { css, Theme } from '@emotion/react';
import ButtonBase from '@mui/material/ButtonBase';

type SubmitButtonProps = {
  clickable: boolean;
  handleClick: () => void;
};

const submitButtonStyles = (theme: Theme) => css({
  display: 'block',
  color: '#ffffff',
  fontWeight: 600,
  background: theme.palette.primaryGradation.default,
  padding: '.8rem 2.4rem',
  margin: '2rem auto',
  '&.Mui-disabled': {
    opacity: .6
  }
});

const SubmitButton: React.VFC<SubmitButtonProps> = (props) => {
  const { clickable, handleClick } = props;

  return(
    <ButtonBase
      css={submitButtonStyles}
      disabled={!clickable}
      onClick={handleClick}
    >
      送信
    </ButtonBase>
  );
};

export default SubmitButton;
