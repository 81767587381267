import React from 'react';

import { css, Theme } from '@emotion/react';
import TextField from '@mui/material/TextField';

type NameFieldProps = {
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
};

const contactFieldStyles = css({
  margin: '1.5rem auto'
});

const NameField: React.VFC<NameFieldProps> = (props) => {
  const {name, setName} = props;

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setName(event.target.value);
  };

  return (
    <TextField
      variant="standard"
      fullWidth
      required
      type="text"
      label="お名前（必須）"
      onChange={(event) => handleChange(event)}
      value={name}
      css={contactFieldStyles}
    />
  );
};

export default NameField;
