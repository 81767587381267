import React from 'react';
import { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { css, Theme } from '@emotion/react';

import Layout from '../layouts/Layout';
import FirstView from '@/components/organisms/common/FirstView';
import Notice from '@/components/organisms/contact/Notice';
import FormBackDrop from '@/components/molecules/contact/FormBackDrop';
import OverViewBand from '@/components/molecules/contact/OverviewBand';
import ContactFormBlock from '@/components/organisms/contact/ContactFormBlock';
import PhoneContactBand from '@/components/molecules/contact/PhoneContactBand';
import BottomMenu from '@/components/organisms/common/BottomMenu';
import Seo from '@/components/organisms/common/Seo';

const FirstViewImage = (theme: Theme) => css({
  height: '25vh',
  [theme.breakpoints.down('xs')]: {
    height: '20vh',
  }
});

const ContactPage: React.VFC<PageProps> = () => {
  return (
    <Layout>
      <Seo
        title="お問い合わせ"
        description="株式会社 富士見工務店へのお問い合わせはこちらのページから。"
      />
      <Notice />
      <FormBackDrop />
      <FirstView
        titleEn="Contact"
        title="お問い合わせ"
        titlePosition="left"
      >
        <StaticImage
          alt="ContactImage"
          src="../images/firstviews/contact.jpg"
          css={FirstViewImage}
          layout="fullWidth"
          objectPosition="50% 50%"
        />
      </FirstView>
      <OverViewBand />
      <ContactFormBlock />
      <PhoneContactBand />
      <BottomMenu />
    </Layout>
  );
};

export default ContactPage;
