import React from 'react';
import { useAtom } from 'jotai';

import { css, Theme } from '@emotion/react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { backDropStatus } from '@/store/store';

const backdrop = (theme: Theme) => css({
  zIndex: theme.zIndex.drawer + 1,
  color: '#ffffff'
});

const FormBackDrop: React.VFC = () => {
  const backDropOpen = useAtom(backDropStatus)[0];

  return (
    <div>
      <Backdrop css={backdrop} open={backDropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default FormBackDrop;
