import React from 'react';

import { css, Theme } from '@emotion/react';
import Box from '@mui/material/Box';

import ContactForm from '@/components/molecules/contact/ContactForm';

const contactFormContentsBox = (theme: Theme) => css({
  backgroundColor: theme.palette.background.default,
  padding: '2rem 0'
});

const ContactFormBlock: React.VFC = () => {
  return (
    <section>
      <Box css={contactFormContentsBox}>
        <ContactForm />
      </Box>
    </section>
  );
};

export default ContactFormBlock;
