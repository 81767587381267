import React from 'react';

import { css, Theme } from '@emotion/react';
import TextField from '@mui/material/TextField';

type EmailFieldProps = {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  isCorrect: boolean;
  setIsCorrect: React.Dispatch<React.SetStateAction<boolean>>;
};

const contactFieldStyles = css({
  margin: '1.5rem auto'
});

const EmailField: React.VFC<EmailFieldProps> = (props) => {
  const {email, setEmail, isCorrect, setIsCorrect} = props;

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setEmail(event.target.value);

    const regex = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

    if (regex.test(event.target.value)) {
      setIsCorrect(true);
    } else {
      setIsCorrect(false);
    }
  };

  return (
    <TextField
      variant="standard"
      fullWidth
      required
      type="email"
      label="メールアドレス（必須）"
      onChange={(event) => handleChange(event)}
      value={email}
      error={!isCorrect}
      helperText={!isCorrect &&  '正しいメールアドレスを入力してください'}
      css={contactFieldStyles}
    />
  );
};

export default EmailField;
